import {HttpClient, HttpParams} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Accp, Message, Position, Salary} from '../interfaces'
import {Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AccrSalaryService {
  constructor(private http: HttpClient) {}

  fetch(body: any = {}): Observable<Accp[]> {

    return this.http.get<Accp[]>('/api/accpsalary', {
      params: new HttpParams({
        fromObject: body
      })
    })

  }
  fetch1(body: any = {}): Observable<Accp[]> {

    return this.http.get<Accp[]>('/api/accpsalary', {
      params: new HttpParams({
        fromObject: body
      })
    })

  }

  create(accp: Accp): Observable<Accp> {
    console.log(accp);

    return this.http.post<Accp>('/api/accpsalary', accp)

  }
  delete(id: string): Observable<Message> {
    return this.http.delete<Message>(`/api/accpsalary/${id}`)
  }
  update(newAccp: Salary): Observable<Accp> {

    return this.http.patch<Accp>(`/api/accpsalary/${newAccp._id}`, newAccp)
  }

}
