import {Component, OnInit} from '@angular/core';
import {AdministratorService} from '../shared/services/administrator.service';
import {Category, User} from '../shared/interfaces';
import {Observable} from 'rxjs/index';


@Component({
  selector: 'app-administrator-page',
  templateUrl: './administrator-page.component.html',
  styleUrls: ['./administrator-page.component.css']
})
export class AdministratorPageComponent implements OnInit {

  users$: Observable<User[]>;

  constructor(private administratorService: AdministratorService  ) {
  }

  ngOnInit() {
    this.users$ = this.administratorService.fetch();
  }

}
