import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {MaterialService} from '../../classes/material.service';
import * as jwt_decode from 'jwt-decode';
import {User} from '../../interfaces';


@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.css']
})
export class SiteLayoutComponent implements OnInit, AfterViewInit {
  info = null;
  tokenn = null;
  @ViewChild('floating') floatingRef: ElementRef;
    links = [
    {url: '/history', name: 'Наряды'}
  ];
    salarylinks = [
      {url: '/salary', name: 'Зарплата'},
      {url: '/salaryplan', name: 'Планирование зарплаты'}
    ];
  adminlinks = [
    {url: '/administrator', name: 'Администратор'},
    {url: '/synch', name: 'Синхронизация'}

  ];

  constructor(private auth: AuthService,
              private router: Router) {
  }

  ngOnInit() {
    this.tokenn = localStorage.getItem('auther-token');
    this.info = jwt_decode(this.tokenn);

  }

  ngAfterViewInit() {
  }

  logout(event: Event) {
    event.preventDefault();
    this.auth.logout();
    this.router.navigate(['/login']);
  }




}
