import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Employee, Filter} from "../../../../shared/interfaces";
import {Subscription} from "rxjs";
import * as jwt_decode from 'jwt-decode';
import {EmployeeService} from "../../../../shared/services/employeers.service";
import {stringify} from "querystring";

@Component({
  selector: 'app-vedomost-filter',
  templateUrl: './vedomost-filter.component.html',
  styleUrls: ['./vedomost-filter.component.css']
})
export class VedomostFilterComponent implements OnInit {
  @Output() onFilter = new EventEmitter<Filter>()
  info = null
  selectEmployeeID: number
  eSub: Subscription
  employee: string
  employeers: Employee[]= [];
  paymentPeriod: Date
  tokenn = null
  constructor(private employeersService: EmployeeService) { }

  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
    this.fetch()
  }
  ngOnDestroy(){

  }

  ngAfterViewInit(){

  }
  private fetch(){
    const params = Object.assign({}, {

    });
    this.eSub = this.employeersService.fetch(params).subscribe( employeers => {
      this.employeers = this.employeers.concat(employeers)

      console.log(this.employeers)
    })
  }
  submitFilter() {
    const filter: Filter = {}
    const filterone: Filter = {}
    const filtertwo: Filter = {}
    if(this.paymentPeriod){
      filter.paymentPeriod = this.paymentPeriod
      filterone.paymentPeriod = this.paymentPeriod
      filtertwo.paymentPeriod = this.paymentPeriod
    }

    if (this.info.role !== 'administrator'){
      filter.employeeID = parseInt(this.info.employeeID,10)
      filterone.employee = this.info.employeeID
      filtertwo.employee = this.info.employeeID

    }
    if (this.info.role === 'administrator'){
      this.employee

      filter.employeeID = parseInt(this.employee,10)
      filter.name = this.employee.match(/[а-яА-Я ]+[^0-9,"]/gu).toString()
      filterone.employee = this.employee
      filtertwo.employee = this.employee
    }


    this.onFilter.emit(filter)


  }
}
