import { Component, Input, OnInit} from '@angular/core'
import {Order} from '../../shared/interfaces'

import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.css']
})
export class HistoryListComponent implements OnInit{
  total = 0
  info = null
  tokenn = null
  @Input() orders: Order[];

  username  = localStorage.getItem("name");

  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
  }



  

}

