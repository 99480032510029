import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MaterialInstance} from '../../shared/classes/material.service'
import { Subscription} from "rxjs";
import {Employee, Filter, Salary} from "../../shared/interfaces";
import {SalaryService} from "../../shared/services/salary.service";
import * as jwt_decode from 'jwt-decode';
import {EmployeeService} from "../../shared/services/employeers.service";

const STEP = 500;
@Component({
  selector: 'app-total-salary',
  templateUrl: './total-salary.component.html',
  styleUrls: ['./total-salary.component.css']
})
export class TotalSalaryComponent implements OnInit, OnDestroy {


  @ViewChild('tooltip') tooltipRef: ElementRef;
  oSub: Subscription;
  employeers: Employee[]= []
  rows: Salary[] = [];
  info = null;
  USERID = null;
  COMPANYID = null;
  employeeID = 0;
  tokenn = null;
  offset = 0;
  limit = STEP;
  reloading: boolean
  tooltip: MaterialInstance;
  isFilterVisible = true;
  filter: Filter = {};
  computePrice: any;
  orderSum: any;
  constructor( private salaryService: SalaryService
  ){}

  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
    this.employeeID = this.info.employeeID
    this.reloading = false;
    const filter: Filter = {};
    if ( this.employeeID) {
      filter.employeeID = this.employeeID
    }
  }

  private fetch() {
    if(this.info.role === 'administrator'){
      this.info.employeeID = null
    }

    const params = Object.assign({}, this.filter, {
      employeeID: this.info.employeeID,
      offset: this.offset,
      limit: this.limit
    });

    this.oSub = this.salaryService.fetch(params).subscribe( salaries =>{
      this.rows = []
      this.rows = this.rows.concat(salaries);
      this.reloading = false
      this.computePrice =this.rows.map(function (order) {
        return order.taskPayout
      });
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      this.orderSum = this.computePrice.reduce(reducer, 0);
    })
  }

  ngOnDestroy() {
    if (this.oSub !== undefined){
      this.oSub.unsubscribe()
    }

  }

  applyFilter(filter: Filter){
    this.offset = 0;
    this.filter = filter;
    this.reloading = true
    this.fetch()

  }

  isFiltered(): boolean {
    return Object.keys(this.filter).length !== 0
  }

}
