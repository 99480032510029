import { Component, OnInit, Inject } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MaterialService} from "../../../shared/classes/material.service";
import { SalaryPlanArticleService } from 'src/app/shared/services/salary-plan-article.service';
import {SalaryPlanArticle} from "../../../shared/interfaces";
import {MatDialogRef,MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-salaryplanarticles-form',
  templateUrl: './salaryplanarticles-form.component.html',
  styleUrls: ['./salaryplanarticles-form.component.css']
})
export class SalaryplanarticlesFormComponent implements OnInit {
  form: FormGroup;
  salaryplanarticle: SalaryPlanArticle;

  constructor(
    private SalaryPlanArticleService: SalaryPlanArticleService,
    public dialogRef: MatDialogRef<SalaryplanarticlesFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SalaryPlanArticle
  ){}
  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      price: new FormControl(null, Validators.required),
      posID: new FormControl(null, Validators.required)

    })
  }
  onSubmit() {
    let obs$
    obs$ = this.SalaryPlanArticleService.create(
      this.form.value
    );


    obs$.subscribe(
      salaryplanarticle => {
        this.salaryplanarticle = salaryplanarticle;
        this.form.enable();
        MaterialService.toast('Изменения сохранены.');
      },
      error => {
        this.form.enable()
      }
    );
  }

}
