import {Component, Input, OnInit} from '@angular/core';
import {Employee, Salary} from "../../../shared/interfaces";
import {Subscription} from "rxjs";
import {SalaryService} from "../../../shared/services/salary.service";
import {ExcelService} from "../../../shared/services/xlsx.service";
import {EmployeeService} from "../../../shared/services/employeers.service";
import {MaterialService} from "../../../shared/classes/material.service";
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-totalitar-salary-list',
  templateUrl: './totalitar-salary-list.component.html',
  styleUrls: ['./totalitar-salary-list.component.css']
})
export class TotalitarSalaryListComponent implements OnInit {
  @Input() rowsy: Salary[];
  employeers: Employee[]= [];
  oSub: Subscription;
  selected = [];
  selectedorder = [];
  tokenn = null;
  info = null;
  reorderable: boolean = true;
  swapColumns: boolean = false;

  constructor(

  ){}
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {

  }

}
