import {HttpClient, HttpParams} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Order, Position, Salary} from '../interfaces'
import {Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SalaryService {
  constructor(private http: HttpClient
  ) {}


  fetch(body: any = {}): Observable<Salary[]> {
    return this.http.get<Salary[]>('/api/salary', {
      params: new HttpParams({
        fromObject: body
      })
    })
  }
  update(newSalary: Salary): Observable<Salary> {

    return this.http.patch<Salary>(`/api/salary/${newSalary._id}`, newSalary)
  }

  synch(body: any = {}): Observable<Salary[]> {
    return this.http.get<Salary[]>(`/api/synch`, {
      params: new HttpParams({
        fromObject: body
      })
    })
  }
  CheckAll(body: any = {}): Observable<Salary[]> {
    return this.http.get<Salary[]>(`/api/sumcheck`, {
      params: new HttpParams({
        fromObject: body
      })
    })
  }

}
