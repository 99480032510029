import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormControl,FormGroup} from '@angular/forms';
import {Company, Employee, Filter} from "../../../shared/interfaces";
import {MaterialDatepicker, MaterialSelect, MaterialService} from "../../../shared/classes/material.service";
import * as jwt_decode from 'jwt-decode';
import {EmployeeService} from "../../../shared/services/employeers.service";
import {stringify} from "querystring";
import {Subscribable, Subscription} from "rxjs";
@Component({
  selector: 'app-totatl-salary-filter',
  templateUrl: './totatl-salary-filter.component.html',
  styleUrls: ['./totatl-salary-filter.component.css']
})
export class TotatlSalaryFilterComponent implements OnInit,OnDestroy,AfterViewInit {
  @Output() onFilter = new EventEmitter<Filter>()
  @ViewChild('startStartTaskDate') startStartTaskDateRef: ElementRef
  @ViewChild('startCompletedTaskDate') startCompletedTaskDateRef: ElementRef
  @ViewChild('endStartTaskDate') endStartTaskDateRef: ElementRef
  @ViewChild('endCompletedTaskDate') endCompletedTaskDateRef: ElementRef
  @ViewChild('selectStatus') selectStatusRef: ElementRef
  employee = new FormControl();
  companyName = new FormControl();
  info = null
  orderNumber: string
  task: string
  oSub: Subscription
  employeers: Employee[]= [];
  companys: Company[]= [];
  contact: string
  orderNumberMedservis: string
  status: string
  tokenn = null
  isValid = true
  startStartTaskDate: MaterialDatepicker
  startCompletedTaskDate: MaterialDatepicker
  endStartTaskDate: MaterialDatepicker
  endCompletedTaskDate: MaterialDatepicker
  paymentPeriod: Date
  selectStatus: MaterialSelect

  constructor(
    private employeersService: EmployeeService
  ) {}


  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
    this.fetch1()
    this.fetch2()
  }

 private fetch1(){
    const params = Object.assign({}, {

    });
    this.oSub = this.employeersService.fetch(params).subscribe( employeers => {
      this.employeers = this.employeers.concat(employeers)
      console.log(this.employeers)
    })
  }
 private fetch2(){
    const params = Object.assign({}, {

    });
    this.oSub = this.employeersService.getAllCompanys(params).subscribe( companys => {
      this.companys = this.companys.concat(companys)
    })
  }
  selectAll(ev){
    const employeevalue = this.employeers.map(function (selectedd) {

      return (selectedd.name)})

    if(ev._selected){
      this.employee.setValue(employeevalue);

      ev._selected=true;
    }
    if(ev._selected==false){
      this.employee.setValue([]);
    }

  }
  selectAllCompany(ev){
    const companyvalue = this.companys.map(function (selectedd) {
      return (selectedd.name)})

    if(ev._selected){
      this.companyName.setValue(companyvalue);
      ev._selected=true;
    }
    if(ev._selected==false){
      this.companyName.setValue([]);
    }

  }
  ngOnDestroy(){
    if (this.startStartTaskDate !== undefined) {
      this.startStartTaskDate.destroy()
    }
    if (this.startCompletedTaskDate !== undefined) {
      this.startCompletedTaskDate.destroy()
    }
    if (this.endStartTaskDate !== undefined) {
      this.endStartTaskDate.destroy()
    }
    if (this.endCompletedTaskDate !== undefined) {
      this.endCompletedTaskDate.destroy()
    }


  }

  ngAfterViewInit() {
      this.startStartTaskDate = MaterialService.initDatepicker(this.startStartTaskDateRef, this.validate.bind(this))
      this.startCompletedTaskDate = MaterialService.initDatepicker(this.startCompletedTaskDateRef, this.validate.bind(this))
      this.endStartTaskDate = MaterialService.initDatepicker(this.endStartTaskDateRef, this.validate.bind(this))
      this.endCompletedTaskDate = MaterialService.initDatepicker(this.endCompletedTaskDateRef, this.validate.bind(this))
      this.selectStatus = MaterialService.initFormSelect(this.selectStatusRef)


  }
  validate() {
    if (!this.startStartTaskDate.date || !this.endStartTaskDate.date) {
      this.isValid = true
      return
    }
    if (!this.startCompletedTaskDate.date || !this.endCompletedTaskDate.date) {
      this.isValid = true
      return
    }

    this.isValid = this.startStartTaskDate.date <=this.endStartTaskDate.date
    this.isValid = this.startCompletedTaskDate.date <=this.endCompletedTaskDate.date
  }

  submitFilter() {
    const filter: Filter = {}
    if(this.paymentPeriod){
      filter.paymentPeriod = new Date(this.paymentPeriod)
    }
    if (this.startStartTaskDate.date !== undefined) {
      filter.start = this.startStartTaskDate.date
    }

    if(this.startCompletedTaskDate.date !== null){
      if (this.startCompletedTaskDate.date !== undefined) {
        filter.startCompletedTaskDate = this.startCompletedTaskDate.date
      }
    }

    if (this.endStartTaskDate.date !== undefined) {
      this.endStartTaskDate.date = new Date(this.endStartTaskDate.date.setDate(this.endStartTaskDate.date.getDate()+1));
      filter.end = this.endStartTaskDate.date;
    }
    if (this.endCompletedTaskDate.date !== undefined) {
      this.endCompletedTaskDate.date = new Date(this.endCompletedTaskDate.date.setDate(this.endCompletedTaskDate.date.getDate()+1));
      filter.endCompletedTaskDate = this.endCompletedTaskDate.date;
    }
    if (this.orderNumber !== undefined) {
      filter.orderNumber = this.orderNumber
    }
    if (this.orderNumberMedservis !== undefined) {
      filter.orderNumberMedservis = this.orderNumberMedservis
    }

    if (this.employee.value !== null ) {
      filter.employee = this.employee.value

    }
    if(this.info.role !== 'administartor'){
      filter.employeeID = this.info.employeeID
    }
    if (this.task !== undefined) {
      filter.task = this.task
    }
    if (this.companyName.value !== null) {
      filter.companyName = this.companyName.value
    }
    if (this.contact !== undefined) {
      filter.contact = this.contact
    }
    if (this.status !== undefined) {
      filter.status = this.status
    }
    this.onFilter.emit(filter)
    if (this.endStartTaskDate.date) {
      this.endStartTaskDate.date = new Date(this.endStartTaskDate.date.setDate(this.endStartTaskDate.date.getDate()-1));

    }
    if (this.endCompletedTaskDate.date) {
      this.endCompletedTaskDate.date = new Date(this.endCompletedTaskDate.date.setDate(this.endCompletedTaskDate.date.getDate()-1));

    }


  }
}
