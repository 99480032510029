import {HttpClient, HttpParams} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Company, Employee, Salary} from '../interfaces'
import {Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  constructor(private http: HttpClient) {}

  fetch(body: any = {}): Observable<Employee[]> {
    return this.http.get<Employee[]>('/api/employee', {
      params: new HttpParams({
        fromObject: body
      })
    })

    }
  getAllCompanys(body: any = {}): Observable<Company[]> {
    return this.http.get<Company[]>('/api/company', {
      params: new HttpParams({
        fromObject: body
      })
    })

    }
  synchUsers(body: any = {}): Observable<Employee[]> {
    return this.http.get<Employee[]>(`/api/synchusers`, {
      params: new HttpParams({
        fromObject: body
      })
    })
  }
  synchCompanyes(body: any = {}): Observable<Company[]> {
    return this.http.get<Company[]>(`/api/synchcompany`, {
      params: new HttpParams({
        fromObject: body
      })
    })
  }
  }

