import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {SalaryPlanHistory} from "../interfaces";

@Injectable({
  providedIn: 'root'
})
export class SalaryplanhistoryService {

  constructor(private http: HttpClient) { }

  fetch(body: any = {}): Observable<SalaryPlanHistory[]> {
    return this.http.get<SalaryPlanHistory[]>('/api/salaryplanhistory', {
        params: new HttpParams({
          fromObject: body
        })
      }
    )
  }
}
