import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {LoginPageComponent} from './login-page/login-page.component'
import {AuthLayoutComponent} from './shared/layouts/auth-layout/auth-layout.component'
import {SiteLayoutComponent} from './shared/layouts/site-layout/site-layout.component'
import {AuthGuard} from './shared/classes/auth.guard'
import {HistoryPageComponent} from './history-page/history-page.component'
import {AdministratorPageComponent} from './administrator-page/administrator-page.component'
import {AdministratorFormComponent} from './administrator-page/administrator-form/administrator-form.component'
import {SalaryPageComponent} from "./salary-page/salary-page.component";
import {SynchronComponent} from "./synchron/synchron.component";
import {SalaryPlanComponent} from "./salaryplan/salaryplan.component";
import {SalaryplangeneralFormComponent} from "./salaryplan/salaryplangeneral/salaryplangeneral-form/salaryplangeneral-form.component";

const routes: Routes = [
  {
    path: '', component: AuthLayoutComponent, children: [
      {path: '', redirectTo: '/login', pathMatch: 'full'},
      {path: 'login', component: LoginPageComponent}
    ]
  },
  {
    path: '', component: SiteLayoutComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
      {path: 'history', component: HistoryPageComponent,data: { role: ['user', 'administrator']}},
      {path: 'administrator', component: AdministratorPageComponent, data: { role: ['administrator']}},
      {path: 'administrator/new', component: AdministratorFormComponent, data: { role: ['administrator']}},
      {path: 'administrator/:id', component: AdministratorFormComponent, data: { role: ['administrator']}},
      {path: 'synch', component: SynchronComponent, data: { role: ['administrator']}},
      {path: 'salary', component: SalaryPageComponent, data: {role: ['administrator', 'employee']}},
      {path: 'salaryplan', component: SalaryPlanComponent, data: {role: ['administrator', 'employee']}},
      {path: 'salaryplan/new', component: SalaryplangeneralFormComponent, data: {role: ['administrator', 'employee']}},
      {path: 'salaryplan/:id', component: SalaryplangeneralFormComponent, data: {role: ['administrator', 'employee']}}
    ]
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
