import {BrowserModule} from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {enableProdMode} from '@angular/core';
enableProdMode();
import {AppComponent} from './app.component';
import {LoginPageComponent} from './login-page/login-page.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthLayoutComponent} from './shared/layouts/auth-layout/auth-layout.component';
import {SiteLayoutComponent} from './shared/layouts/site-layout/site-layout.component';
import {TokenInterceptor} from './shared/classes/token.interceptor';
import {HistoryPageComponent} from './history-page/history-page.component';
import {AdministratorPageComponent} from './administrator-page/administrator-page.component';
import {LoaderComponent} from './shared/components/loader/loader.component';
import {AdministratorFormComponent} from './administrator-page/administrator-form/administrator-form.component';
import {HistoryListComponent} from './history-page/history-list/history-list.component';
import {HistoryFilterComponent} from './history-page/history-filter/history-filter.component';
import { SalaryPageComponent } from './salary-page/salary-page.component';
import { TotalSalaryComponent } from './salary-page/total-salary/total-salary.component';
import { TotalSalaryListComponent } from './salary-page/total-salary/total-salary-list/total-salary-list.component';
import {TotalitarSalaryListComponent} from './salary-page/totalitar-salary/totalitar-salary-list/totalitar-salary-list.component';
import {DemoMaterialModule} from '../material-modules';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TotatlSalaryFilterComponent } from './salary-page/total-salary/totatl-salary-filter/totatl-salary-filter.component';
import { TotalitarSalaryComponent } from './salary-page/totalitar-salary/totalitar-salary.component';
import { TotalitarSalaryFilterComponent } from './salary-page/totalitar-salary/totalitar-salary-filter/totalitar-salary-filter.component';
import { AccrSalaryComponent } from './salary-page/accr-salary/accr-salary.component';
import { AccrFormComponent } from './salary-page/accr-salary/accr-form/accr-form.component';
import { VedomostComponent } from './salary-page/vedomost/vedomost.component';
import { VedomostListComponent } from './salary-page/vedomost/vedomost-list/vedomost-list.component';
import { VedomostFilterComponent } from './salary-page/vedomost/vedomost-list/vedomost-filter/vedomost-filter.component';
import { SynchronComponent } from './synchron/synchron.component';
import { SelectCheckAllComponent } from './salary-page/total-salary/totatl-salary-filter/select-check-all/select-check-all.component';
import { SalaryPlanComponent } from './salaryplan/salaryplan.component';
import { SalaryPlanarticlesComponent} from './salaryplan/salaryplanarticles/salaryplanarticles.component'
import { SalaryplanarticlesFormComponent } from './salaryplan/salaryplanarticles/salaryplanarticles-form/salaryplanarticles-form.component';
import { SalaryplangeneralComponent } from './salaryplan/salaryplangeneral/salaryplangeneral.component';
import { MatTableModule, MatPaginatorModule, MatSortModule } from '@angular/material';
import { SalaryplangeneralTableComponent } from './salaryplan/salaryplangeneral/salaryplangeneral-table/salaryplangeneral-table.component';
import { SalaryplangeneralFormComponent } from './salaryplan/salaryplangeneral/salaryplangeneral-form/salaryplangeneral-form.component';
import { SalaryplangeneralFilterComponent } from './salaryplan/salaryplangeneral/salaryplangeneral-filter/salaryplangeneral-filter.component';
import { SalaryplanHistoryComponent } from './salaryplan/salaryplan-history/salaryplan-history.component';
import { SalaryplanhistoryTableComponent } from './salaryplan/salaryplan-history/salaryplanhistory-table/salaryplanhistory-table.component';
import { SalaryplanhistoryFilterComponent } from './salaryplan/salaryplan-history/salaryplanhistory-filter/salaryplanhistory-filter.component';
import {SortByPipe} from './salaryplan/salaryplangeneral/salaryplangeneral-form/order-by.pipe';
registerLocaleData(localeRu);
@NgModule({

    declarations: [
        AppComponent,
        LoginPageComponent,
        AuthLayoutComponent,
        SiteLayoutComponent,
        HistoryPageComponent,
        AdministratorPageComponent,
        LoaderComponent,
        AdministratorFormComponent,
        HistoryListComponent,
        HistoryFilterComponent,
        SalaryPageComponent,
        TotalSalaryComponent,
        TotalSalaryListComponent,
        TotatlSalaryFilterComponent,
        TotalitarSalaryComponent,
        TotalitarSalaryFilterComponent,
        TotalitarSalaryListComponent,
        AccrSalaryComponent,
        AccrFormComponent,
        VedomostComponent,
        VedomostListComponent,
        VedomostFilterComponent,
        SynchronComponent,
        SelectCheckAllComponent,
        SalaryPlanComponent,
        SalaryPlanarticlesComponent,
        SalaryplanarticlesFormComponent,
        SalaryplangeneralComponent,
        SalaryplangeneralTableComponent,
        SalaryplangeneralFormComponent,
        SalaryplangeneralFilterComponent,
        SalaryplanHistoryComponent,
        SalaryplanhistoryTableComponent,
        SalaryplanhistoryFilterComponent,
      SortByPipe
    ],
  imports: [
    BrowserModule,
    [BrowserAnimationsModule],
    [NgxDatatableModule],
    DemoMaterialModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule
  ],
  entryComponents: [
    SalaryplanarticlesFormComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: TokenInterceptor
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
}
