import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import {Filter} from '../../shared/interfaces'
import {MaterialDatepicker, MaterialSelect, MaterialService} from '../../shared/classes/material.service'
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-history-filter',
  templateUrl: './history-filter.component.html',
  styleUrls: ['./history-filter.component.css']
})
export class HistoryFilterComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() onFilter = new EventEmitter<Filter>()
  @ViewChild('start') startRef: ElementRef
  @ViewChild('end') endRef: ElementRef
  @ViewChild('selectOrderStage') selectOrderStageRef: ElementRef
  @ViewChild('selectpaymentState') selectpaymentStateRef: ElementRef
  info = null
  tokenn = null
  start: MaterialDatepicker
  end: MaterialDatepicker
  orderNumber: string
  orderStage: string
  paymentState: string
  patient: string
  company: string
  isValid = true
  selectOrderStage: MaterialSelect
  selectpaymentState: MaterialSelect


  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
  }

  ngOnDestroy() {
    this.start.destroy()
    this.end.destroy()
    this.selectOrderStage.destroy()
  }

  ngAfterViewInit() {
    this.start = MaterialService.initDatepicker(this.startRef, this.validate.bind(this))
    this.end = MaterialService.initDatepicker(this.endRef, this.validate.bind(this))
    this.selectOrderStage = MaterialService.initFormSelect(this.selectOrderStageRef)
    this.selectpaymentState = MaterialService.initFormSelect(this.selectpaymentStateRef)
  }


  validate() {
    if (!this.start.date || !this.end.date) {
      this.isValid = true
      return
    }

    this.isValid = this.start.date <=this.end.date
  }

  submitFilter() {
    const filter: Filter = {}

    if (this.orderNumber) {
      filter.orderNumber = this.orderNumber
    }

    if (this.start.date) {
      filter.start = this.start.date
    }

    if (this.end.date) {
      this.end.date = new Date(this.end.date.setDate(this.end.date.getDate()+1));
      filter.end = this.end.date;
    }
    if (this.orderStage) {
      filter.orderStage = this.orderStage
    }
    if(this.paymentState) {
      filter.paymentState = this.paymentState
    }
    if(this.patient) {
      filter.patient = this.patient

    }
    if(this.company) {
      filter.company = this.company
    }
    this.onFilter.emit(filter)
    if (this.end.date) {
      this.end.date = new Date(this.end.date.setDate(this.end.date.getDate()-1));

    }
  }


}
