import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as Excel from 'exceljs';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  constructor() { }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  public exportAsExcelFileVedomost(orderSum1: any[],accrplus: any[],
                                   orderSum2: any[],accrminus: any[],
                                   orderSum3: any[], filter: any[], employee: any[]
  ):void{
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Зарплатная ведомость');

  }
  public exportAssExcelFile(data: any[], excelFileName: string): void {
    let size = 40; //размер подмассива
    let subarray = []; //массив в который будет выведен результат.
    for (let i = 0; i <Math.ceil(data.length/size); i++){
      subarray[i] = data.slice((i*size), (i*size) + size);

    }
    const transpose = matrix => matrix[0].map((col, i) => matrix.map(row => row[i]));
    const transposedMatrix = transpose(subarray);

    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Список нарядов');

      worksheet.addRows(transposedMatrix);
    worksheet.eachRow({ includeEmpty: true }, function(row, rowNumber) {
      row.eachCell(function(cell, colNumber) {
        cell.border = {
          top: {style:'thin'},
          left: {style:'thin'},
          bottom: {style:'thin'},
          right: {style:'thin'}
        };
      });
    });
    //Generate Excel File with given name
    workbook.xlsx.writeBuffer()
      .then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      FileSaver.saveAs(blob, 'Список_нарядов.xlsx');
    })
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }
}
