import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MaterialService, MaterialTab} from "../shared/classes/material.service";
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: "app-salaryplan",
  templateUrl: "./salaryplan.component.html",
  styleUrls: ["./salaryplan.component.css"]
})
export class SalaryPlanComponent implements OnInit {

  @ViewChild("selectTab") selectTabRef: ElementRef;
  selectTab: MaterialTab;
  tokenn = null;
  info = null;

  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token");
    this.info = jwt_decode(this.tokenn);
    console.log(this.info)
  }

  ngOnDestroy() {
    if (this.selectTab !== undefined) {
      this.selectTab.destroy();
    }
  }

  ngAfterViewInit() {
    this.selectTab = MaterialService.initTabs(this.selectTabRef);
    console.log("init");
  }
}
