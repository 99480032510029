import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../shared/services/auth.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MaterialService} from '../shared/classes/material.service';



@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit, OnDestroy {

  form: FormGroup;
  aSub: Subscription;

  constructor(private auth: AuthService,
              private  router: Router,
              private  route: ActivatedRoute) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      tel: new FormControl(null, [Validators.maxLength(11)]),
      password: new FormControl(null, [Validators.required,
        Validators.minLength(6)])
    });

    this.route.queryParams.subscribe((params: Params) => {
    if (params['registered']) {
      MaterialService.toast('Теперь вы можете зайти в систему используя свои данные');
      // Теперь вы можете зайти в систему используя свои данные
    } else if (params['accessDenied']) {
      MaterialService.toast('Авторизуйтесь в системе');
      // Авторизуйтесь в системе
    } else if (params['sessionFailed']) {
      MaterialService.toast('Войдите в систему заново');
    }
    });
  }

  ngOnDestroy() {
    if (this.aSub) {
      this.aSub.unsubscribe();
    }
  }

  onSubmit() {
    this.form.disable();
    this.aSub = this.auth.login(this.form.value).subscribe(
      () => this.router.navigate(['/history']),
      error => {

      }
    );
  }

}
