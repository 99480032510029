import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Salary, SalaryPlanArticle, SalaryPlanGeneral, User} from "../interfaces";

@Injectable({
  providedIn: 'root'
})
export class SalaryplangeneralService {

  constructor(private http: HttpClient) { }

  fetch(body: any = {}): Observable<SalaryPlanGeneral[]> {
    return this.http.get<SalaryPlanGeneral[]>('/api/salaryplangeneral', {
        params: new HttpParams({
          fromObject: body
        })
      }
    )
  }
  create(salaryPlanGeneral: SalaryPlanGeneral): Observable<SalaryPlanGeneral> {
    console.log(salaryPlanGeneral);
    return this.http.post<SalaryPlanGeneral>('api/salaryplangeneral', salaryPlanGeneral);
  }

  getByID(id: string): Observable<SalaryPlanGeneral> {

    return this.http.get<SalaryPlanGeneral>(`/api/salaryplangeneral/${id}`)
  }


  update(salaryPlanGeneral: SalaryPlanGeneral, id: any): Observable<SalaryPlanGeneral> {

    return this.http.patch<SalaryPlanGeneral>(`/api/salaryplangeneral/${id}`, {salaryPlanGeneral, id})
  }

  }
