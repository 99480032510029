import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Accp, Employee} from '../../../shared/interfaces';
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {AccrSalaryService} from '../../../shared/services/accr-salary.service'
import {MaterialDatepicker, MaterialSelect, MaterialService} from "../../../shared/classes/material.service";
import {EmployeeService} from "../../../shared/services/employeers.service";
import {Subscription} from "rxjs";
@Component({
  selector: 'app-accr-form',
  templateUrl: './accr-form.component.html',
  styleUrls: ['./accr-form.component.css']
})
export class AccrFormComponent implements OnInit {
  @ViewChild('input') inputRef: ElementRef
  @Output() employeersout = new EventEmitter<Employee[]>();
  form: FormGroup
  constructor(
    private accrSalaryService: AccrSalaryService,
    private employeersService: EmployeeService
  ) { }
  accp: Accp
  asSub: Subscription
  @ViewChild('start') startRef: ElementRef
  @ViewChild('selecttype') selecttypeRef: ElementRef
  selecttype: MaterialSelect
  employeers: Employee[]= [];
  isValid = true
  ngOnInit() {
    this.form = new FormGroup({
      Date: new FormControl(null, Validators.required),
      paymentPeriod: new FormControl(null, Validators.required),
      employee: new FormControl(null, Validators.required),
      typee: new FormControl(null, Validators.required),
      sum: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
    })
    this.fetch()
  }

  private fetch(){
    const params = Object.assign({}, {

    });
    this.asSub = this.employeersService.fetch(params).subscribe( employeers => {
      this.employeers = this.employeers.concat(employeers)

    })
  }
  ngOnDestroy() {
    this.selecttype.destroy()
  }
  ngAfterViewInit() {
    this.selecttype = MaterialService.initFormSelect(this.selecttypeRef)
  }
  validate() {
    this.isValid = true
  }
  onSubmit() {
    let obs$
    obs$ = this.accrSalaryService.create(
      this.form.value
    )


    obs$.subscribe(
      accp => {
        this.accp = accp
        this.form.enable()
        MaterialService.toast('Изменения сохранены.');
      },
      error => {
        this.form.enable()
      }
    )
  }

}
