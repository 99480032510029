import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Accp, Employee, Filter} from "../../shared/interfaces";
import {MaterialInstance, MaterialService} from "../../shared/classes/material.service";
import {AccrSalaryService} from "../../shared/services/accr-salary.service";
import * as jwt_decode from 'jwt-decode';
import {EmployeeService} from "../../shared/services/employeers.service";

@Component({
  selector: 'app-accr-salary',
  templateUrl: './accr-salary.component.html',
  styleUrls: ['./accr-salary.component.css']
})
export class AccrSalaryComponent implements OnInit, OnDestroy {
  @Input() employeersout: Employee[]
  xSub: Subscription;
  assSub: Subscription;
  accr: Accp[] = [];
  newAccp: any;
  accrone: Accp;
  info = null;
  editing = {};
  USERID = null;
  filter: Filter = {};
  COMPANYID = null;
  employeers: Employee[]= [];
  employeeID = 0;
  tokenn = null;
  role = null
  reloading: boolean
  tooltip: MaterialInstance;
  constructor( private accpSalaryService: AccrSalaryService,
               private employeersService: EmployeeService
              ) { }

  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
    this.employeeID = this.info.employeeID
    this.reloading = true;

    const filter: Filter = {};
  }
  updateValue(event, cell, rowIndex) {

    console.log('inline editing rowIndex', rowIndex);
    this.editing[rowIndex + '-' + cell] = false;

    this.accr[rowIndex][cell] = event.target.value;
    this.accr = [...this.accr];

    console.log('UPDATED!', this.accr[rowIndex][cell]);

    this.newAccp = this.accr[rowIndex];
    if (cell == "status"){
      this.newAccp.statusDate = Date.now();

    }
    const newSalary = this.accpSalaryService.update(this.newAccp).subscribe(
      newSalary => {
        MaterialService.toast('Изменения сохранены')

      },
      error =>
        console.log(error)
      )

    ;


  }
   private fetch() {
    if(this.info.role === 'administrator'){
      this.info.employeeID = null
    }
     if (this.info.role !== 'administrator'){
       this.filter.employeeID = parseInt(this.info.employeeID,10)
     }

    const params = Object.assign({},this.filter,{

    });

    this.xSub = this.accpSalaryService.fetch(params).subscribe( accr =>{
      this.accr = []
      this.accr = this.accr.concat(accr);
      this.reloading = false

    })
  }
  private fetchemp(){
    const params = Object.assign({}, {

    });
    this.assSub = this.employeersService.fetch(params).subscribe( employeers => {
      this.employeers = this.employeers.concat(employeers)

    })
  }

  ngOnDestroy(){
    if(this.xSub !== undefined){
      this.xSub.unsubscribe()
    }

  }
  deleteCategory(row) {
    console.log(row)

    const decision = window.confirm(`Вы уверены, что хотите удалить категорию "${row.typee}"`)

    if (decision) {
      this.accpSalaryService.delete(row._id)
        .subscribe(
          response => MaterialService.toast(response.message),
          error => MaterialService.toast(error.error.message)
        );
      this.fetch()
    }

  }


  applyFilter(){

    this.reloading = true;
    this.fetch()
    this.fetchemp()
  }


}
