import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from "@angular/router";
import {Observable, of} from "rxjs";
import {Injectable} from "@angular/core";
import {AuthService} from "../services/auth.service";
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild{
  constructor(private auth: AuthService,
              private router: Router) {

  }
  info = null;
  tokenn = null;
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>{
    if(this.auth.isAuthenticated()) {
      return of(true)
    } else {
      this.router.navigate(['/login'],
        {
          queryParams: {
            accessDenied: true
          }
        })

        return of(false)
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean{

    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
    let myRole = this.info.role// Получаем роль при инициализации приложения запросом на backend либо по своему усмотрению
    let roles = route.data['role'] as Array<string>;

    if (!roles || roles.indexOf(myRole) != -1) return this.canActivate(route, state)
    else {
      this.router.navigate(['/salary']); //или на страницу авторизации
      return
    }

  }

}
