import { Component, OnInit } from '@angular/core';
import {Filter, SalaryPlanGeneral} from "../../shared/interfaces";
import { SalaryplangeneralService } from 'src/app/shared/services/salaryplangeneral.service';
import * as jwt_decode from "jwt-decode";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-salaryplangeneral',
  templateUrl: './salaryplangeneral.component.html',
  styleUrls: ['./salaryplangeneral.component.css']
})
export class SalaryplangeneralComponent implements OnInit {
  salaryplangeneral: SalaryPlanGeneral[]=[];
  info = null;
  salaryGeneralSub: Subscription;
  USERID = null;
  COMPANYID = null;
  employeeID = 0;
  tokenn = null;
  role = null;
  reloading: boolean
  isFilterVisible = true;
  filter: Filter = {};
  constructor(private salaryplangeneralService: SalaryplangeneralService) { }

  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
    this.reloading = true;
    const filter: Filter = {};
    this.fetch()
  }

  private fetch() {
    const params = Object.assign({}, this.filter, {
    });

    this.salaryGeneralSub = this.salaryplangeneralService.fetch(params).subscribe( salaryplangeneral =>{
      this.salaryplangeneral = this.salaryplangeneral.concat(salaryplangeneral);
      this.reloading = false
    })
  }

  applyFilter(filter: Filter){
    this.salaryplangeneral = [];


    this.filter = filter;
    this.reloading = true;
    this.fetch()


  }

  isFiltered(): boolean {
    return Object.keys(this.filter).length !== 0
  }

}
