import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'materialize-css/dist/js/materialize';





platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
