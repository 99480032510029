import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {Company, Employee, Salary} from "../shared/interfaces";
import {SalaryService} from "../shared/services/salary.service";
import {EmployeeService} from "../shared/services/employeers.service";

@Component({
  selector: 'app-synchron',
  templateUrl: './synchron.component.html',
  styleUrls: ['./synchron.component.css']
})
export class SynchronComponent implements OnInit {
  oSub: Subscription;
  uSub: Subscription;
  cSub: Subscription;
  rows: Salary[] = [];
  rowsUsers: Employee[] = [];
  rowsCompany: Company[] = [];
  constructor(
    private salaryService: SalaryService,
    private employeeService: EmployeeService,
  ) { }

  ngOnInit() {
  }
  private synch() {

    const params = Object.assign({}, {
    });

    this.oSub = this.salaryService.synch(params).subscribe( salaries =>{
      this.rows = []
      this.rows = this.rows.concat(salaries);
      });
    }
  private synchUsers() {

    const params = Object.assign({}, {
    });

    this.uSub = this.employeeService.synchUsers(params).subscribe( salaries =>{
      this.rowsUsers = []
      this.rowsUsers = this.rowsUsers.concat(salaries);
    });
  }
  private synchCompanyes() {

    const params = Object.assign({}, {
    });

    this.cSub = this.employeeService.synchCompanyes(params).subscribe( companyes =>{
      this.rowsCompany = []
      this.rowsCompany = this.rowsCompany.concat(companyes);
    });
  }

  synchSalary(){
    this.synch()
  }
  synchEmploeers(){
    this.synchUsers()
  }
  startsynchCompanyes(){
    this.synchCompanyes()
  }

}
