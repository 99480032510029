import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {Filter, Salary} from "../../shared/interfaces";
import {MaterialInstance} from "../../shared/classes/material.service";
import {SalaryTotalService} from "../../shared/services/totalitar-salary.service";
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-totalitar-salary',
  templateUrl: './totalitar-salary.component.html',
  styleUrls: ['./totalitar-salary.component.css']
})
export class TotalitarSalaryComponent implements OnInit {



  @ViewChild('tooltip') tooltipRef: ElementRef;
  tSub: Subscription;
  computePrice: any;
  orderSum: any;
  totalitar: Salary[] = [];
  info = null;
  USERID = null;
  COMPANYID = null;
  employeeID = 0;
  tokenn = null;
  role = null;
  employee: string
  reloading: boolean
  tooltip: MaterialInstance;
  isFilterVisible = true;
  filter: Filter = {};
  constructor( private salaryTotalService: SalaryTotalService){}

  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
    this.employee = this.info.employeeID
    this.reloading = true;
    const filter: Filter = {};
  }

  private fetch() {
    if(this.info.role === 'administrator'){
      this.info.employeeID = null
    }

    const params = Object.assign({}, this.filter, {

    });

    this.tSub = this.salaryTotalService.fetch(params).subscribe( salaries =>{
      this.totalitar = this.totalitar.concat(salaries);
      this.reloading = false
      this.computePrice =this.totalitar.map(function (order) {
        return order.totalPayout
      });
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      this.orderSum = this.computePrice.reduce(reducer, 0);

    })
  }

  ngOnDestroy() {

  }

  applyFilter(filter: Filter){
    this.totalitar = [];
    if ( this.employee) {
      filter.employee = this.info.employeeID
    }

    this.filter = filter;
    this.reloading = true;
    this.fetch()


  }

  isFiltered(): boolean {
    return Object.keys(this.filter).length !== 0
  }
}
