import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Filter} from "../../../shared/interfaces";
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-salaryplanhistory-filter',
  templateUrl: './salaryplanhistory-filter.component.html',
  styleUrls: ['./salaryplanhistory-filter.component.css']
})
export class SalaryplanhistoryFilterComponent implements OnInit {

  @Output() onFilter = new EventEmitter<Filter>()
  info = null
  orderNumber: string
  ID: number
  historyID: number
  tokenn = null
  constructor() { }

  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
  }

  ngOnDestroy(){
  }

  ngAfterViewInit() {
  }

  submitFilter() {
    const filter: Filter = {}
    if (this.orderNumber) {
      filter.orderNumber = this.orderNumber
    }
    if (this.ID){
      filter.ID = this.ID
    }
    if (this.historyID){
      filter.historyID = this.historyID
    }
    this.onFilter.emit(filter)
  }
}
