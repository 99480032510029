import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Employee, Filter} from "../../../shared/interfaces";
import * as jwt_decode from 'jwt-decode';
import {EmployeeService} from "../../../shared/services/employeers.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-totalitar-salary-filter',
  templateUrl: './totalitar-salary-filter.component.html',
  styleUrls: ['./totalitar-salary-filter.component.css']
})
export class TotalitarSalaryFilterComponent implements OnInit {
  @Output() onFilter = new EventEmitter<Filter>()
  info = null
  eSub: Subscription
  orderNumber: string
  selectEmployeeID: number
  employeers: Employee[]= [];
  companyName: string
  patient: string
  paymentPeriod: Date
  tokenn = null
  constructor(private employeersService: EmployeeService) { }

  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
    this.fetch()
  }
  ngOnDestroy(){
  }

  ngAfterViewInit() {

  }
  private fetch(){
    const params = Object.assign({}, {

    });
    this.eSub = this.employeersService.fetch(params).subscribe( employeers => {
      this.employeers = this.employeers.concat(employeers)

      console.log(this.employeers)
    })
  }

  submitFilter() {
    const filter: Filter = {}
    if (this.orderNumber) {
      filter.orderNumber = this.orderNumber
    }
    if (this.paymentPeriod) {
      filter.paymentPeriod = this.paymentPeriod
    }
    if (this.companyName) {
      filter.companyName = this.companyName
    }
    if (this.patient) {
      filter.patient = this.patient
    }
    if (this.selectEmployeeID){
      filter.employeeID = this.selectEmployeeID
    }
    if (this.info.role !== 'administrator'){
      filter.employeeID = parseInt(this.info.employeeID,10)
    }

    this.onFilter.emit(filter)
  }
}
