import {
  AfterViewInit,
  Component, ElementRef, Injectable,
  Input,
  OnDestroy,
  OnInit, Output, ViewChild,
} from '@angular/core';
import {Employee, Salary} from "../../../shared/interfaces";
import {SalaryService} from "../../../shared/services/salary.service";
import {MaterialSelect, MaterialService} from "../../../shared/classes/material.service";
import {ExcelService} from '../../../shared/services/xlsx.service';
import * as jwt_decode from 'jwt-decode';
import {from, Observable, Subscription} from "rxjs";
import {EmployeeService} from "../../../shared/services/employeers.service";
import {stringify} from "querystring";


@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-total-salary-list',
  templateUrl: './total-salary-list.component.html',
  styleUrls: ['./total-salary-list.component.css']
})
export class TotalSalaryListComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() rows: Salary[];
  setpaymentPeriod: Date
  employeers: Employee[]= [];
  oSub: Subscription;
  sumSub: Subscription;
  selected = [];
  selectedorder = [];
  tokenn = null;
  info = null;
  newSalary: any;
  editing = {};
  reorderable: boolean = true;
  swapColumns: boolean = false;
  computePrice: any;
  orderSum: any;

  constructor(
    private salaryService: SalaryService,
    private excelService:ExcelService,
    private employeersService: EmployeeService
  ){}


private fetch(){
    const params = Object.assign({}, {

    });
    this.oSub = this.employeersService.fetch(params).subscribe( employeers => {
      this.employeers = this.employeers.concat(employeers)
      console.log(this.employeers)
    })
}
  updateValue(event, cell, rowIndex, row) {
    this.editing[rowIndex + '-' + cell] = false;
    row[cell] =  event.target.value;
    this.rows = [...this.rows];
    this.newSalary = row;
    if (cell == "status"){
      this.newSalary.statusDate = Date.now();
    }
    const newSalary = this.salaryService.update(this.newSalary).subscribe(
      newSalary => {
        MaterialService.toast('Изменения сохранены')
      },
      error =>
      console.log(error)
    )

    ;


  }
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  Checkstatus(){
    console.log(this.setpaymentPeriod)
    let salaryService = this.salaryService
    let paymentPeriod = this.setpaymentPeriod

    this.selected.forEach(function (item) {
      item.paymentPeriod = paymentPeriod

      item.status = 'Выплачено'
      item.statusDate = new Date()
      const newSalary = salaryService.update(item).subscribe(
        newSalary => {
          const idx = item.ID;
          this.rows[idx] = newSalary;
          MaterialService.toast('Изменения сохранены')

        },
        error =>
          console.log(error)
      );
    })
  }
  Checkstatus1(){
    console.log(this.setpaymentPeriod)
    let salaryService = this.salaryService
    this.computePrice =this.selected.map(function (order) {
      return order.taskPayout
    });
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    this.orderSum = this.computePrice.reduce(reducer, 0);
    if(this.orderSum === parseInt(this.selected[0].orderPayout, 10)){
       let orderPayout = parseInt(this.selected[0].orderPayout, 10)
      this.selected.forEach(function (item) {
        if(item.status !== 'Выплачено'){
          item.status = 'Проверено'
        }
        item.orderPayout = orderPayout
        const newSalary = salaryService.update(item).subscribe(
          newSalary => {

          },
          error =>
            console.log(error)
        );
      })
      MaterialService.toast('Проверено')
    } else if (this.orderSum !== parseInt(this.selected[0].orderPayout, 10)) {
      let orderPayout = parseInt(this.selected[0].orderPayout, 10)
       this.selected.forEach(function (item) {
        item.status = 'Загружено'
         item.statusDate = new Date()
        item.orderPayout = orderPayout
        const newSalary = salaryService.update(item).subscribe(
          newSalary => {


          },
          error =>
            console.log(error)
        );
      })

      MaterialService.toast('Зарпалат по наряду '+orderPayout+' Сумма операций '+this.orderSum)
    }
    this.selected
  }
 CheckAll(){
   const params = Object.assign({}, {
   });
   this.sumSub = this.salaryService.CheckAll(params).subscribe( salary => {
     this.employeers = this.employeers.concat(salary)
     console.log(this.employeers)
     MaterialService.toast('Проверка закончена')
   })
 }

  exportAsXLSX() {
    this.selectedorder.push(...this.selected.map(function(select) {
      return select.orderNumber
    }))
    function unique(arr) {
      var obj = [];

      for (var i = 0; i < arr.length; i++) {
        var str = arr[i];
        obj[str] = true; // запомнить строку в виде свойства объекта
      }
      return ([...Object.keys(obj).map(function (selectedd) {
        return (selectedd)
        })]

      );
      // или собрать ключи перебором для IE8-

    }
    this.excelService.exportAssExcelFile(unique(this.selectedorder), 'sample');

  }

  exportXLSX() {
    this.excelService.exportAsExcelFile(this.selected, 'sample');
  }
  onActivate(event) {
    console.log('Activate Event', event);
  }

  getRowClass(row) {
    return {
      'green': row.task  == 'РАБОТА СДАНА',
      'blue' : row.task == 'Счет оплачен!!!'
    };
  }

  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
    this.fetch()

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {

  }


}
