import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-vedomost',
  templateUrl: './vedomost.component.html',
  styleUrls: ['./vedomost.component.css']
})
export class VedomostComponent implements OnInit {
  constructor(
  ) { }
  ngOnInit() {

  }


}
