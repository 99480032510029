import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Category, Message, User} from '../interfaces';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdministratorService {
  constructor(private http: HttpClient) {
  }

  fetch(): Observable<User[]> {
    return this.http.get<User[]>('/api/auth')
  }

  getByID(id: string): Observable<User> {
    return this.http.get<User>(`/api/category/${id}`)
  }

  update(id: any, user: User
  ): Observable<User> {

    return this.http.patch<User>(`/api/category/${id}`, {user, id})

  }

  delete(id: string): Observable<Message> {
    return this.http.delete<Message>(`/api/category/${id}`)
  }
}
