import {HttpClient, HttpParams} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Position, Salary} from '../interfaces'
import {Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SalaryTotalService {
  constructor(private http: HttpClient) {}

  fetch(body: any = {}): Observable<Salary[]> {
    return this.http.get<Salary[]>('/api/salary-total', {
      params: new HttpParams({
        fromObject: body
      })
    })
  }

}
