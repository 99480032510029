import {Component, Input, OnInit} from '@angular/core';
import {Filter, SalaryPlanGeneral, SalaryPlanHistory} from "../../shared/interfaces";
import {Subscription} from "rxjs";
import {SalaryplanhistoryService} from "../../shared/services/salaryplanhistory.service";
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-salaryplan-history',
  templateUrl: './salaryplan-history.component.html',
  styleUrls: ['./salaryplan-history.component.css']
})
export class SalaryplanHistoryComponent implements OnInit {

  @Input() Salaryplanhistory: SalaryPlanHistory[]=[];
  info = null;
  salaryHistorySub: Subscription;
  USERID = null;
  COMPANYID = null;
  employeeID = 0;
  tokenn = null;
  role = null;
  reloading: boolean
  isFilterVisible = true;
  filter: Filter = {};
  constructor(private SalaryplanhistoryService: SalaryplanhistoryService) { }

  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
    this.reloading = true;
    const filter: Filter = {};
    this.fetch()
  }

  private fetch() {
    const params = Object.assign({}, this.filter, {
    });

    this.salaryHistorySub = this.SalaryplanhistoryService.fetch(params).subscribe( Salaryplanhistory =>{
      this.Salaryplanhistory = this.Salaryplanhistory.concat(Salaryplanhistory);
      this.Salaryplanhistory = Array.from(this.Salaryplanhistory)
      this.Salaryplanhistory.forEach(function(item, i, arr) {
        item.newpos = [...item.position, ...item.oldposition].flat(2);
        item.newpos.sort( function(a, b) {
          if (a.positionarticleID > b.positionarticleID) return 1; // если первое значение больше второго
          if (a.positionarticleID == b.positionarticleID) return 0; // если равны
          if (a.positionarticleID < b.positionarticleID) return -1; // если первое значение меньше второго

        });
        item.pos = []
        console.log(item.newpos.length)
        for (var i = 0; i <= item.position.length; i++) {
          item.pos[i] = item.newpos.splice(0,2)
        }
        item.pos.push([...item.newpos])
      });
      console.log(this.Salaryplanhistory)
      this.reloading = false


    })
  }

  applyFilter(filter: Filter){
    this.Salaryplanhistory = [];


    this.filter = filter;
    this.reloading = true;
    this.fetch()


  }

  isFiltered(): boolean {
    return Object.keys(this.filter).length !== 0
  }

}
