import { Component, OnInit } from '@angular/core';
import {SalaryPlanArticle} from '../../shared/interfaces';
import {Observable, Subscribable, Subscription} from "rxjs";
import {SalaryPlanArticleService} from '../../shared/services/salary-plan-article.service'
import * as jwt_decode from "jwt-decode";
import {MaterialInstance, MaterialService} from "../../shared/classes/material.service";
import {MatDialog} from "@angular/material/dialog";
import {SalaryplanarticlesFormComponent} from "./salaryplanarticles-form/salaryplanarticles-form.component";



@Component({
  selector: 'app-salaryplanarticles',
  templateUrl: './salaryplanarticles.component.html',
  styleUrls: ['./salaryplanarticles.component.css']
})

export class SalaryPlanarticlesComponent implements OnInit {

  SalaryPlanArticles: SalaryPlanArticle[] = [];
  SalaryPlanArticleSub: Subscription;
  newSalaryPlanArticles: any;
  editing = {};
  reloading: boolean;
  role = null;
  info = null;
  tokenn = null;
  tooltip: MaterialInstance;
  constructor(private SalaryPlanArticleService: SalaryPlanArticleService,
              public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn);
    this.reloading = true;
    this.fetch()
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(SalaryplanarticlesFormComponent, {
    });
    dialogRef.afterClosed().subscribe(result => {
      setTimeout(()=>{this.fetch()},3000);
    });
  }

  private fetch() {
    const params = Object.assign({},{
    });

    this.SalaryPlanArticleSub = this.SalaryPlanArticleService.fetch(params).subscribe( SalaryPlanArticles =>{
      this.SalaryPlanArticles = []
      this.SalaryPlanArticles = this.SalaryPlanArticles.concat(SalaryPlanArticles);
      this.reloading = false
      console.log(this.SalaryPlanArticles)

    })
  }

  updateValue(event, cell, rowIndex) {

    console.log('inline editing rowIndex', rowIndex);
    this.editing[rowIndex + '-' + cell] = false;

    this.SalaryPlanArticles[rowIndex][cell] = event.target.value;
    this.SalaryPlanArticles = [...this.SalaryPlanArticles];

    console.log('UPDATED!', this.SalaryPlanArticles[rowIndex][cell]);

    this.newSalaryPlanArticles = this.SalaryPlanArticles[rowIndex];
    if (cell == "status"){
      this.newSalaryPlanArticles.statusDate = Date.now();

    }
    const newSalaryPlanArticle = this.SalaryPlanArticleService.update(this.newSalaryPlanArticles).subscribe(
      newSalaryPlanArticle => {
        MaterialService.toast('Изменения сохранены')

      },
      error =>
        console.log(error)
      )

    ;


  }

  deleteCategory(row) {
    console.log(row)

    const decision = window.confirm(`Вы уверены, что хотите удалить категорию "${row.name}"`)

    if (decision) {
      this.SalaryPlanArticleService.delete(row._id)
        .subscribe(
          response => MaterialService.toast(response.message),
          error => MaterialService.toast(error.error.message)
        );
      this.fetch()
    }

  }


}
