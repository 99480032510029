import {Component, Input, OnInit, ViewChild} from '@angular/core';
import * as jwt_decode from "jwt-decode";
import {SalaryPlanGeneral} from "../../../shared/interfaces";

@Component({
  selector: 'app-salaryplangeneral-table',
  templateUrl: './salaryplangeneral-table.component.html',
  styleUrls: ['./salaryplangeneral-table.component.css']
})
export class SalaryplangeneralTableComponent implements OnInit {
  @Input() rowsy: SalaryPlanGeneral[];
  tokenn = null;
  info = null;
  selected = [];
  selectedorder = [];
  reorderable: boolean = true;
  swapColumns: boolean = false;

  constructor() { }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
  }


}
