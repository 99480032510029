import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SalaryPlanGeneral, SalaryPlanHistory} from "../../../shared/interfaces";
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-salaryplanhistory-table',
  templateUrl: './salaryplanhistory-table.component.html',
  styleUrls: ['./salaryplanhistory-table.component.css']
})
export class SalaryplanhistoryTableComponent implements OnInit {
  @ViewChild('mydatatable') table: any;
  @Input() rowsy: SalaryPlanHistory[];
  tokenn = null;
  info = null;
  selected = [];
  selectedorder = [];
  reorderable: boolean = true;
  swapColumns: boolean = false;

  constructor() { }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }
}
