import {Component, OnInit, AfterViewChecked} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {SalaryPlanArticle, SalaryPlanGeneral, User} from "../../../shared/interfaces";
import {of, Subscription, BehaviorSubject} from "rxjs";
import {SalaryPlanArticleService} from "../../../shared/services/salary-plan-article.service";
import * as jwt_decode from "jwt-decode";
import {MaterialService} from "../../../shared/classes/material.service";
import { SalaryplangeneralService } from 'src/app/shared/services/salaryplangeneral.service';
import {switchMap, toArray} from "rxjs/operators";
import {ActivatedRoute, Params, Router} from '@angular/router';

// @ts-ignore
@Component({
  selector: 'app-salaryplangeneral-form',
  templateUrl: './salaryplangeneral-form.component.html',
  styleUrls: ['./salaryplangeneral-form.component.css']
})
export class SalaryplangeneralFormComponent implements OnInit, AfterViewChecked {
  form: FormGroup;
  positions: SalaryPlanArticle[] = [];
  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayColumns = ['from', 'to'];
  salaryPlanGeneral: SalaryPlanGeneral
  itemColor= []
  role = null;
  info = null;
  tokenn = null;
  isNew = true;
  isBack = false;
  numbsCount=[];
  Sum=[]
  sum = 0
  numbs = 0
  positionsAdd = []
  reducer = (accumulator, currentValue) => accumulator + currentValue;
  reducer1 = (accumulator, currentValue) => accumulator + currentValue;
  SalaryPlanArticleSub: Subscription
  constructor(private fb: FormBuilder,
              private SalaryplangeneralService: SalaryplangeneralService,
              private SalaryPlanArticleService: SalaryPlanArticleService,
              private route: ActivatedRoute,
              private router: Router
              ) {
  }

  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn);
    this.fetch();
    this.form = new FormGroup ({
      orderNumber: new FormControl ('', [Validators.required], ),
      name: new FormControl ('', [Validators.required]),
      numbs: new FormControl (0),
      sum: new FormControl (0),
      user: new FormControl ( '' ,),
      date: new FormControl (null, [Validators.required]),
      positionss: new FormArray([])
    });
    this.getparams()
  }
  ngAfterViewChecked(){
  }
   sortTable() {
    var table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById("myTable");
    switching = true;
    /*Make a loop that will continue until
    no switching has been done:*/
    while (switching) {
      //start by saying: no switching is done:
      switching = false;
      rows = table.rows;

      /*Loop through all table rows (except the
      first, which contains table headers):*/
      for (i = 1; i < (rows.length - 1); i++) {
        //start by saying there should be no switching:
        shouldSwitch = false;
        /*Get the two elements you want to compare,
        one from current row and one from the next:*/
        x = rows[i].getElementsByTagName("TH")[4];
        y = rows[i + 1].getElementsByTagName("TH")[4];

        //check if the two rows should switch place:
        if (parseInt(x.innerHTML,10) > parseInt(y.innerHTML,10)) {
          //if so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      }
      if (shouldSwitch) {
        /*If a switch has been marked, make the switch
        and mark that a switch has been done:*/
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;

      }
    }
  }


  onChange(newValue, index) {
    this.Sum[index] = newValue
   this.sum = this.Sum.reduce(this.reducer)
  }
  onChange1(newValue, index) {
    if(newValue == null|| newValue ==''){

      this.numbsCount[index] = 0
      this.numbs = this.numbsCount.reduce(this.reducer1)
    } else {
      this.numbsCount[index] = parseInt(newValue, 10)
      this.numbs = this.numbsCount.reduce(this.reducer1)
    }

  }

  check(newValue,index){
    this.itemColor[index] = 'rgba(255, 254, 0, 0.3)'
}
  private addPositions(){
    let self = this
   this.positionsAdd.forEach(function (item,i,positionsAdd) {
     (<FormArray>self.form.get("positionss")).insert(
       item.articleID,
       new FormGroup(
         {
           positionNum: new FormControl(0,Validators.min(0)),
           positionSum: new FormControl(0),
           positionarticleID: new FormControl( item.articleID),
           positionName: new FormControl(item.name),
           positionPrice: new FormControl(item.price),
           positionposID: new FormControl(item.posID)
         }
       ));
   })


  }
  getparams(){
    this.route.params
      .pipe(
        switchMap(
          (params: Params) => {
            if (params['id']) {
              this.isNew = false;
              return this.SalaryplangeneralService.getByID(params['id']);
            }
            return of(null)
          }

        )
      )
      .subscribe(
        (salaryPlanGeneral: SalaryPlanGeneral) => {
          if (salaryPlanGeneral) {
            this.salaryPlanGeneral = salaryPlanGeneral;
            this.form.patchValue({
              orderNumber: salaryPlanGeneral.orderNumber,
              name: salaryPlanGeneral.name,
              numbs: salaryPlanGeneral.numbs,
              sum: salaryPlanGeneral.sum,
              user: this.info.name,
              date: salaryPlanGeneral.date
            });
            let self = this
            let iTem = null
            this.salaryPlanGeneral.position.forEach(
              function (item,i,position) {
                iTem = item.shift();
                if (i  == position.indexOf(item)) {
                  (<FormArray>self.form.get("positionss")).at(i).patchValue({
                    positionNum: iTem.positionNum,
                    itemSet: iTem.itemSet
                  });
                  if(iTem.positionNum > 0){
                    self.itemColor[i] = 'rgba(0,75,255,0.37)'
                  }

                }

            })
            this.sortTable()
          }


          this.form.enable()
        },
        error => MaterialService.toast(error.error.message)
      )
  }
  getControls(frmGrp: FormGroup, key: string) {
    return (<FormArray>frmGrp.controls[key]).controls;
  }

  private fetch() {
    const params = Object.assign({},{
    });

    this.SalaryPlanArticleSub = this.SalaryPlanArticleService.fetch(params).subscribe( SalaryPlanArticles =>{
      this.positions = []
      this.positions = this.positions.concat(SalaryPlanArticles)
      this.positionsAdd = this.positions
      this.addPositions()
    })

  }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  completeClass(){
    this.itemColor.forEach(
      function (item,i,itemColor) {
        itemColor[i] = 'rgba(0,255,41,0.3)'

        return itemColor

      }
      )

  }
  onSubmit() {
    let obs$;
    if (this.isNew === true) {
      this.form.value.date = new Date()
      obs$ = this.SalaryplangeneralService.create(this.form.value);
    }
    else {
      this.form.value.date = new Date()
      obs$ = this.SalaryplangeneralService.update(
        this.form.value,
      this.salaryPlanGeneral._id,
      );

    }
    obs$.subscribe(
      salaryPlanGeneral => {
        this.salaryPlanGeneral = salaryPlanGeneral;

        MaterialService.toast('Изменения сохранены.');
        this.form.enable()
        if (this.isBack == true) {
          this.router.navigateByUrl('/salaryplan');
        }
      },
      error => {

        MaterialService.toast(error.error.message);
        this.form.enable();
      }
    );
  }






}
