import { Component, OnInit } from '@angular/core';
import {SalaryTotalService} from "../../../shared/services/totalitar-salary.service";
import {AccrSalaryService} from "../../../shared/services/accr-salary.service";
import {Accp, Employee, Filter, Salary} from "../../../shared/interfaces";
import { Subscription} from "rxjs";
import * as jwt_decode from 'jwt-decode';
import {EmployeeService} from "../../../shared/services/employeers.service";
import {ExcelService} from "../../../shared/services/xlsx.service";

@Component({
  selector: 'app-vedomost-list',
  templateUrl: './vedomost-list.component.html',
  styleUrls: ['./vedomost-list.component.css']
})
export class VedomostListComponent implements OnInit {

  constructor(
    private salaryService: SalaryTotalService,
    private accpSalaryService: AccrSalaryService,
    private excelService:ExcelService,
    private employeersService: EmployeeService
  ) { }
  totalsalary: Salary[] = [];
  info = null;
  computePrice1: any;
  orderSum1: any;
  computePrice2: any;
  orderSum2: any;
  computePrice3: any;
  orderSum3: any;
  oneSub:Subscription;
  twoSub: Subscription;
  threeSub: Subscription;
  empSub: Subscription;
  USERID = null;
  role = null
  employee: string
  accrplus: Accp[] =[]
  accrminus: Accp[] =[]
  employeers: Employee[]= [];
  filter: Filter = {};
  filterone: Filter = {};
  filtertwo: Filter = {};
  isFilterVisible = true;
  COMPANYID = null;
  tokenn = null;
  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
    this.employee = this.info.employeeID
    const filter: Filter = {};
    const filterone: Filter = {};
    const filtertwo: Filter = {};
    if ( this.employee) {
      filter.employee = this.info.employeeID
    }
    if ( this.employee) {
      filterone.employee = this.info.employeeID
    }
    if ( this.employee) {
      filtertwo.employee = this.info.employeeID
    }
    this.fetch4()
  }
  private fetch1() {
    this.totalsalary = []
    const params = Object.assign({}, this.filter, {
    });

    this.oneSub = this.salaryService.fetch(params).subscribe( salaries =>{
      this.totalsalary = this.totalsalary.concat(salaries);
      this.computePrice1 =this.totalsalary.map(function (order) {
        return order.totalPayout
      });
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      this.orderSum1 = this.computePrice1.reduce(reducer, 0);

    })
  }

  private fetch2() {

    const params = Object.assign({},this.filterone,{
      typee: 'Начисление'
    });
    this.twoSub = this.accpSalaryService.fetch(params).subscribe( accr =>{
      this.accrplus = this.accrplus.concat(accr);
      this.computePrice2 =this.accrplus.map(function (order) {
        return order.sum
      });
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      this.orderSum2 = this.computePrice2.reduce(reducer, 0);



    })
  }
  private fetch3() {
    const params = Object.assign({},this.filtertwo,{
      typee: 'Удержание'

    });

    this.threeSub = this.accpSalaryService.fetch(params).subscribe( accr =>{
      this.accrminus = this.accrminus.concat(accr);
      this.computePrice3 =this.accrminus.map(function (order) {
        return order.sum
      });
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      this.orderSum3 = this.computePrice3.reduce(reducer, 0);

    })
  }
  private fetch4(){
    const params = Object.assign({}, {

    });
    this.empSub = this.employeersService.fetch(params).subscribe( employeers => {
      this.employeers = this.employeers.concat(employeers)

      console.log(this.employeers)
    })
  }
  ngOnDestroy() {
    if(this.oneSub !== undefined){
      this.oneSub.unsubscribe()
    }
    if(this.twoSub !== undefined){
      this.twoSub.unsubscribe()
    }
    if(this.threeSub !== undefined){
      this.threeSub.unsubscribe()
    }
  }

  applyFilter(filter: Filter){
    this.filter = filter;
    this.filterone = filter;
    this.filtertwo = filter;

    this.totalsalary = [];
    this.accrplus =[]
    this.accrminus =[]
    this.fetch1()
    this.fetch2()
    this.fetch3()

  }
  exportXLSXved() {
    const filterinfo = [this.filter]
    this.excelService.exportAsExcelFileVedomost(this.orderSum1,this.accrplus,
                                                this.orderSum2,this.accrminus,
                                                this.orderSum3, filterinfo,
                                                 [this.employee]
      );

  }

}
