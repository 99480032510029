import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MaterialService, MaterialTab} from "../shared/classes/material.service";
import * as jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-salary-page',
  templateUrl: './salary-page.component.html',
  styleUrls: ['./salary-page.component.css']
})
export class SalaryPageComponent implements OnInit {

  @ViewChild('selectTab') selectTabRef: ElementRef;
  selectTab: MaterialTab;
  tokenn = null
  info =null


  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
  }



  ngOnDestroy() {

    if (this.selectTab !== undefined){
      this.selectTab.destroy()
    }
  }


  ngAfterViewInit() {
    this.selectTab = MaterialService.initTabs(this.selectTabRef)
    console.log('init')
  }



}
