import {ElementRef} from '@angular/core'

declare var M

export interface MaterialInstance {
  open?(): void
  close?(): void
  destroy?(): void
}

export interface MaterialDatepicker extends MaterialInstance {
  date?: Date
  Date?: Date
}
export interface MaterialSelect extends MaterialInstance{
  orderStage?: string
  status?: string
  role?: string

}

export interface MaterialTab extends MaterialInstance{
  tabs?: any
}

export interface MaterialDropdown extends MaterialInstance{
  dropdown? : any

}

export class MaterialService {
  static toast(message: string) {
    M.toast({html: message})
  }



  static updateTextInputs() {
    M.updateTextFields()
  }

  static initModal(ref: ElementRef): MaterialInstance {
    return M.Modal.init(ref.nativeElement)
  }

  static initTooltip(ref: ElementRef): MaterialInstance {
    return M.Tooltip.init(ref.nativeElement)
  }

  static initDatepicker(ref: ElementRef, onClose: () => void): MaterialDatepicker {
    return M.Datepicker.init(ref.nativeElement, {
      format: 'dd.mm.yyyy',
      showClearBtn: true,
      onClose
    })
  }
  static initDatepickerMonth(ref: ElementRef, onClose: () => void): MaterialDatepicker {
    return M.Datepicker.init(ref.nativeElement, {
      format: 'mm.yyyy',
      selectMonths: true,
      selectYears: false,
      buttonImageOnly: false,
      showClearBtn: true,
      onClose
    })
  }

  static initTapTarget(ref: ElementRef): MaterialInstance {
    return M.TapTarget.init(ref.nativeElement)
  }
  static initFormSelect(ref: ElementRef) {
    return M.FormSelect.init(ref.nativeElement)
  }
  static initTabs(ref: ElementRef): MaterialInstance {
    return M.Tabs.init(ref.nativeElement);
  }
  static initDropdown(ref: ElementRef): MaterialInstance {
    return M.Dropdown.init(ref.nativeElement)
  }
}
