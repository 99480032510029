import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import {AbstractControl} from "@angular/forms";

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
  transform(value: AbstractControl[], order = '', column: string = ''): any[] {

    if (!value || order === '' || !order) { return value; } // no array
    if (!column || column === '') { return _.sortBy(value); } // sort 1d array
    if (value.length <= 1) { return value; } // array with only one item
    return _.orderBy(value, [column], [order]);
  }
}
