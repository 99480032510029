import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Filter} from "../../../shared/interfaces";
import {Subscription} from "rxjs";
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-salaryplangeneral-filter',
  templateUrl: './salaryplangeneral-filter.component.html',
  styleUrls: ['./salaryplangeneral-filter.component.css']
})
export class SalaryplangeneralFilterComponent implements OnInit {

  @Output() onFilter = new EventEmitter<Filter>()
  info = null
  orderNumber: string
  ID: number
  tokenn = null
  constructor() { }

  ngOnInit() {
    this.tokenn = localStorage.getItem("auther-token")
    this.info = jwt_decode(this.tokenn)
  }

  ngOnDestroy(){
  }

  ngAfterViewInit() {
  }

  submitFilter() {
    const filter: Filter = {}
    if (this.orderNumber) {
      filter.orderNumber = this.orderNumber
    }
    if (this.ID){
      filter.ID = this.ID
    }
    this.onFilter.emit(filter)
  }
}
