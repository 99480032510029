import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {MaterialInstance, MaterialService} from '../shared/classes/material.service'
import {OrdersService} from '../shared/services/orders.service'
import {Subscription} from 'rxjs'
import {Filter, Order} from '../shared/interfaces'
import * as jwt_decode from 'jwt-decode';


const STEP = 20;

@Component({
  selector: 'app-history-page',
  templateUrl: './history-page.component.html',
  styleUrls: ['./history-page.component.css']
})
export class HistoryPageComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('tooltip') tooltipRef: ElementRef;
  computePrice: any;
  orderSum: any;
  info = null;
  tokenn = null;
  USERID = null;
  COMPANYID = null;
  contact = null;
  tooltip: MaterialInstance;
  oSub: Subscription;
  isFilterVisible = true;
  orders: Order[] = [];
  filter: Filter = {};
  offset = 0;
  limit = STEP;


  loading = false;
  reloading = false;
  noMoreOrders = false;

  constructor(private ordersService: OrdersService) {
  }

  ngOnInit() {
      this.tokenn = localStorage.getItem("auther-token")
      this.info = jwt_decode(this.tokenn)
    this.reloading = true
    this.USERID = this.info.USERID
    this.COMPANYID = this.info.COMPANYID

    const filter: Filter = {};
    if (this.USERID) {
      filter.USERID = this.USERID
    }
    if (this.COMPANYID) {
      filter.COMPANYID = this.COMPANYID
    }
    this.fetch()

  }

  private fetch() {
    if(this.info.role === 'administrator'){
      this.info.USERID = 'null'
      this.info.COMPANYID = ""
    }
    const params = Object.assign({}, this.filter, {
      USERID: this.info.USERID,
      COMPANYID: this.info.COMPANYID,
      offset: this.offset,
      limit: this.limit

    });
    this.oSub = this.ordersService.fetch(params).subscribe(orders => {
      this.orders = this.orders.concat(orders);
      this.computePrice =this.orders.map(function (order) {
        return order.sum
      });
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      this.orderSum = this.computePrice.reduce(reducer, 0);
      this.noMoreOrders = orders.length < STEP;
      this.loading = false;
      this.reloading = false

    })
  }

  loadMore() {
    this.offset += STEP;
    this.loading = true;
    this.fetch()
  }

  ngOnDestroy() {
    this.tooltip.destroy();
    this.oSub.unsubscribe()
  }

  applyFilter(filter: Filter) {
    this.orders = [];
    this.offset = 0;
    this.filter = filter;
    this.reloading = true;
    this.fetch()
  }

  ngAfterViewInit() {
    this.tooltip = MaterialService.initTooltip(this.tooltipRef)
  }

  isFiltered(): boolean {
    return Object.keys(this.filter).length !== 0
  }

}
