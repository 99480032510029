import { Injectable } from '@angular/core';
import {Accp, Message, Salary, SalaryPlanArticle, User} from '../interfaces';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SalaryPlanArticleService {

  constructor(private http:HttpClient) {
  }

  fetch(body: any = {}): Observable<SalaryPlanArticle[]> {
    return this.http.get<SalaryPlanArticle[]>('/api/salaryplanarticle', {
        params: new HttpParams({
          fromObject: body
        })
      }
      )
  }


  getByID(id: string): Observable<SalaryPlanArticle> {
    return this.http.get<SalaryPlanArticle>(`/api/salaryplanarticle/${id}`)
  }
  update(newSalaryPlanArticles: SalaryPlanArticle): Observable<SalaryPlanArticle> {

    return this.http.patch<SalaryPlanArticle>(`/api/salaryplanarticle/${newSalaryPlanArticles._id}`, newSalaryPlanArticles)
  }

  delete(id: string): Observable<Message> {
    return this.http.delete<Message>(`/api/salaryplanarticle/${id}`)
  }

  create(salaryplanarticle: SalaryPlanArticle): Observable<SalaryPlanArticle> {
    console.log(salaryplanarticle);

    return this.http.post<SalaryPlanArticle>('/api/salaryplanarticle', salaryplanarticle)

  }
}
