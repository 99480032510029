import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdministratorService} from '../../shared/services/administrator.service';
import {switchMap} from 'rxjs/operators';
import {from, of, Subscription} from 'rxjs';
import {MaterialSelect, MaterialService, MaterialTab} from '../../shared/classes/material.service';
import {Category, User} from '../../shared/interfaces';
import {AuthService} from '../../shared/services/auth.service';

@Component({
  selector: 'app-administrator-form',
  templateUrl: './administrator-form.component.html',
  styleUrls: ['./administrator-form.component.css']
})
export class AdministratorFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('selectTab') selectTabRef: ElementRef;
  @ViewChild('selectSalaryTab') selectSalaryTabRef: ElementRef;
  @ViewChild('selectSalaryPlanTab') selectSalaryPlanTabRef: ElementRef;
  selectTab: MaterialTab;
  selectSalaryTab: MaterialTab;
  selectSalaryPlanTab: MaterialTab;
  @ViewChild('input') inputRef: ElementRef;
  form: FormGroup;
  isNew = true;
  role: string;
  aSub: Subscription;
  user: User;
  USERID: string;

  constructor(private auth: AuthService,
              private administratorService: AdministratorService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      tel: new FormControl(null, [Validators.required, Validators.minLength(11), Validators.maxLength(11)]),
      password: new FormControl(null, [Validators.minLength(6)]),
      USERID: new FormControl(null, [Validators.maxLength(11)]),
      COMPANYID: new FormControl(null, [Validators.maxLength(11)]),
      employeeID: new FormControl(null, [Validators.maxLength(11)]),
      role: new FormControl(null ,[Validators.required]),
      name: new FormControl(null, [Validators.required]),
      companyname: new FormControl(null, [Validators.required]),
      accessname: new FormControl(false, [Validators.required]),
      accesscheckbox: new FormControl(false, [Validators.required]),
      accessmenu: new FormControl(false, [Validators.required]),
      accessdate: new FormControl(false, [Validators.required]),
      accessorderNumber: new FormControl(false, [Validators.required]),
      accesspatient: new FormControl(false, [Validators.required]),
      accesskindOfWork: new FormControl(false, [Validators.required]),
      accessnumberProducts: new FormControl(false, [Validators.required]),
      accesscolor: new FormControl(false, [Validators.required]),
      accessorderStage: new FormControl(false, [Validators.required]),
      accessproductionStage: new FormControl(false, [Validators.required]),
      accesscompany: new FormControl(false, [Validators.required]),
      accesssum: new FormControl(false, [Validators.required]),
      accesspaymentState: new FormControl(false, [Validators.required]),
      accessbillNumber: new FormControl(false, [Validators.required]),
      accessbillDate: new FormControl(false, [Validators.required]),
      accesstotalPayout_Salary: new FormControl(false, [Validators.required]),
      accessbillLink: new FormControl(false, [Validators.required]),
      accessbillFile: new FormControl(false, [Validators.required]),
      accessworkFile: new FormControl(false, [Validators.required]),
      accesschippingInformation: new FormControl(false, [Validators.required]),
      // accessmaterial: new FormControl(false, [Validators.required]),
      accessfilterdate: new FormControl(false, [Validators.required]),
      acessfilterorderstage: new FormControl(false, [Validators.required]),
      accessfilterpaymentstate: new FormControl(false, [Validators.required]),
      accessfilterpatient: new FormControl(false, [Validators.required]),
      accessfiltercompany: new FormControl(false, [Validators.required]),
      accessfiltercontact: new FormControl(false, [Validators.required]),
      accesscheckbox_Salary: new FormControl(false, [Validators.required]),
      accessID: new FormControl(false, [Validators.required]),
      accessemployee: new FormControl(false, [Validators.required]),
      accessstartTaskDate: new FormControl(false, [Validators.required]),
      accesscompletedTaskDate: new FormControl(false, [Validators.required]),
      accesstask: new FormControl(false, [Validators.required]),
      accesstaskPayout: new FormControl(false, [Validators.required]),
      accessnumberOfItemsComplete: new FormControl(false, [Validators.required]),
      accessorderNumber_Salary: new FormControl(false, [Validators.required]),
      accesslinkToZTL: new FormControl(false, [Validators.required]),
      accesscompanyName: new FormControl(false, [Validators.required]),
      accesscontact: new FormControl(false, [Validators.required]),
      accessorderManager: new FormControl(false, [Validators.required]),
      accessProductionStage_Salary: new FormControl(false, [Validators.required]),
      accesskindOfWork_Salary: new FormControl(false, [Validators.required]),
      accesspatient_Salary: new FormControl(false, [Validators.required]),
      accessdentition: new FormControl(false, [Validators.required]),
      accesscolor_Salary: new FormControl(false, [Validators.required]),
      accessnumberOfItems: new FormControl(false, [Validators.required]),
      accessorderNumberMedservis: new FormControl(false, [Validators.required]),
      accessprimacyOfWork: new FormControl(false, [Validators.required]),
      accessstatus: new FormControl(false, [Validators.required]),
      accessstatusDate: new FormControl(false, [Validators.required]),
      filterOrderNumberSelary: new FormControl(false, [Validators.required]),
      filterpaymentPeriodSelary: new FormControl(false, [Validators.required]),
      filterOrderNumberMedservisSelary: new FormControl(false, [Validators.required]),
      filterStatusSelary: new FormControl(false, [Validators.required]),
      filterEmployeeSelary: new FormControl(false, [Validators.required]),
      filterTaskSelary: new FormControl(false, [Validators.required]),
      filterContactSelary: new FormControl(false, [Validators.required]),
      acccessSalaryTab: new FormControl(false, [Validators.required]),
      acccessSalaryAllTab: new FormControl(false, [Validators.required]),
      acccessAccpTab: new FormControl(false, [Validators.required]),
      acccessVedomostTab: new FormControl(false, [Validators.required]),
      accesseSalaryEditing: new FormControl(false, [Validators.required]),
      accessorderNumber_SalaryTotal: new FormControl(false, [Validators.required]),
      period_SalaryTotal:new FormControl(false, [Validators.required]),
      accesscompanyName_SalaryTotal: new FormControl(false, [Validators.required]),
      accesspatient_SalaryTotal: new FormControl(false, [Validators.required]),
      accesstaskPayout_SalaryTotal: new FormControl(false, [Validators.required]),
      filterStartTaskDateSelary: new FormControl(false, [Validators.required]),
      filterCompletedTaskDateSelary: new FormControl(false, [Validators.required]),
      acccessSalaryPlanGeneralTab: new FormControl(false, [Validators.required]),
      acccessSalaryPlanHistoryTab: new FormControl(false, [Validators.required]),
      acccessSalarySettingTab: new FormControl(false, [Validators.required]),
      acccessSalaryPlanGeneralEdit: new FormControl(false, [Validators.required]),
      acccessSalaryPlanGeneralID: new FormControl(false, [Validators.required]),
      acccessSalaryPlanGeneralorderNumber: new FormControl(false, [Validators.required]),
      acccessSalaryPlanGeneralNumbs: new FormControl(false, [Validators.required]),
      acccessSalaryPlanGeneralSum: new FormControl(false, [Validators.required]),
      acccessSalaryPlanGeneralUser: new FormControl(false, [Validators.required]),
      acccessSalaryPlanGeneralDate: new FormControl(false, [Validators.required]),
      acccessSalaryPlanHistoryDetails: new FormControl(false, [Validators.required]),
      acccessSalaryPlanHistoryID: new FormControl(false, [Validators.required]),
      acccessSalaryPlanHistoryHistoryID: new FormControl(false, [Validators.required]),
      acccessSalaryPlanHistoryorderNumber: new FormControl(false, [Validators.required]),
      acccessSalaryPlanHistoryNumbs: new FormControl(false, [Validators.required]),
      acccessSalaryPlanHistorySum: new FormControl(false, [Validators.required]),
      acccessSalaryPlanHistoryUser: new FormControl(false, [Validators.required]),
      acccessSalaryPlanHistoryDate: new FormControl(false, [Validators.required]),
      acccessSalaryPlanHistoryDetailspositionarticleID: new FormControl(false, [Validators.required]),
      acccessSalaryPlanHistoryDetailspositionName: new FormControl(false, [Validators.required]),
      acccessSalaryPlanHistoryDetailspositionNum: new FormControl(false, [Validators.required]),
      acccessSalaryPlanHistoryDetailspositionSum: new FormControl(false, [Validators.required]),
      acccessSalaryPlanHistoryDetailspositionPrice: new FormControl(false, [Validators.required]),
      acccessSalarySettingAdd: new FormControl(false, [Validators.required]),
      acccessSalarySettingDel: new FormControl(false, [Validators.required]),
      acccessSalarySettingarticleID: new FormControl(false, [Validators.required]),
      acccessSalarySettingname: new FormControl(false, [Validators.required]),
      acccessSalarySettingprice: new FormControl(false, [Validators.required]),
      acccessSalarySet: new FormControl(false, [Validators.required])
    });

    this.form.disable();

    this.route.params
      .pipe(
        switchMap(
          (params: Params) => {
            if (params['id']) {
              this.isNew = false;
              return this.administratorService.getByID(params['id']);
            }

            return of(null)
          }
        )
      )
      .subscribe(
        (user: User) => {
          if (user) {
            this.user = user;
            this.form.patchValue({
              id: user._id,
              tel: user.tel,
              password: user.pass,
              USERID: user.USERID,
              COMPANYID: user.COMPANYID,
              employeeID: user.employeeID,
              role: user.role,
              name: user.name,
              companyname: user.companyname,
              accessname: user.accessname,
              accesscheckbox: user.accesscheckbox,
              accessmenu: user.accessmenu,
              accessdate: user.accessdate,
              accessorderNumber: user.accessorderNumber,
              accesspatient: user.accesspatient,
              accesskindOfWork: user.accesskindOfWork,
              accessnumberProducts: user.accessnumberProducts,
              accesscolor: user.accesscolor,
              accessorderStage: user.accessorderStage,
              accessproductionStage: user.accessproductionStage,
              accesscompany: user.accesscompany,
              accesssum: user.accesssum,
              accesspaymentState: user.accesspaymentState,
              accessbillNumber: user.accessbillNumber,
              accessbillDate: user.accessbillDate,
              accessbillLink: user.accessbillLink,
              accessbillFile: user.accessbillFile,
              accessworkFile: user.accessworkFile,
              accesschippingInformation: user.accesschippingInformation,
              // accessmaterial: user.,
              accessfilterdate: user.accessfilterdate,
              acessfilterorderstage: user.acessfilterorderstage,
              accessfilterpaymentstate: user.accessfilterpaymentstate,
              accessfilterpatient: user.accessfilterpatient,
              accessfiltercompany: user.accessfiltercompany,
              accessfiltercontact: user.accessfiltercontact,
              accesscheckbox_Salary: user.accesscheckbox_Salary,
              accessID: user.accessID,
              accessemployee: user.accessemployee,
              accessstartTaskDate: user.accessstartTaskDate,
              accesscompletedTaskDate: user.accesscompletedTaskDate,
              accesstask: user.accesstask,
              accesstaskPayout: user.accesstaskPayout,
              accessnumberOfItemsComplete: user.accessnumberOfItemsComplete,
              accessorderNumber_Salary: user.accessorderNumber_Salary,
              accesslinkToZTL: user.accesslinkToZTL,
              accesscompanyName: user.accesscompanyName,
              accesscontact: user.accesscontact,
              accessorderManager: user.accessorderManager,
              accessProductionStage_Salary: user.accessProductionStage_Salary,
              accesskindOfWork_Salary: user.accesskindOfWork_Salary,
              accesspatient_Salary: user.accesspatient_Salary,
              accessdentition: user.accessdentition,
              accesscolor_Salary: user.accesscolor_Salary,
              accesstotalPayout_Salary: user.accesstotalPayout_Salary,
              accessnumberOfItems: user.accessnumberOfItems,
              accessorderNumberMedservis: user.accessorderNumberMedservis,
              accessprimacyOfWork: user.accessprimacyOfWork,
              accessstatus: user.accessstatus,
              accessstatusDate: user.accessstatusDate,
              filterOrderNumberSelary: user.filterOrderNumberSelary,
              filterpaymentPeriodSelary:user.filterpaymentPeriodSelary,
              filterOrderNumberMedservisSelary:user.filterOrderNumberMedservisSelary,
              filterStatusSelary:user.filterStatusSelary,
              filterEmployeeSelary:user.filterEmployeeSelary,
              filterTaskSelary:user.filterTaskSelary,
              filterContactSelary:user.filterContactSelary,
              acccessSalaryTab: user.acccessSalaryTab ,
              acccessSalaryAllTab: user.acccessSalaryAllTab,
              acccessAccpTab: user.acccessAccpTab,
              acccessVedomostTab: user.acccessVedomostTab,
              accesseSalaryEditing: user.accesseSalaryEditing,

              accessorderNumber_SalaryTotal: user.accessorderNumber_SalaryTotal,
              period_SalaryTotal: user.period_SalaryTotal,
              accesscompanyName_SalaryTotal: user.accesscompanyName_SalaryTotal,
              accesspatient_SalaryTotal: user.accesspatient_SalaryTotal,
              accesstaskPayout_SalaryTotal: user.accesstaskPayout_SalaryTotal,
              filterStartTaskDateSelary: user.filterStartTaskDateSelary,
              filterCompletedTaskDateSelary: user.filterCompletedTaskDateSelary,

              acccessSalaryPlanGeneralTab: user.acccessSalaryPlanGeneralTab,
              acccessSalaryPlanHistoryTab: user.acccessSalaryPlanHistoryTab,
              acccessSalarySettingTab: user.acccessSalarySettingTab,
              acccessSalaryPlanGeneralEdit: user.acccessSalaryPlanGeneralEdit,
              acccessSalaryPlanGeneralID: user.acccessSalaryPlanGeneralID,
              acccessSalaryPlanGeneralorderNumber: user.acccessSalaryPlanGeneralorderNumber,
              acccessSalaryPlanGeneralNumbs: user.acccessSalaryPlanGeneralNumbs,
              acccessSalaryPlanGeneralSum: user.acccessSalaryPlanGeneralSum,
              acccessSalaryPlanGeneralUser: user.acccessSalaryPlanGeneralUser,
              acccessSalaryPlanGeneralDate: user.acccessSalaryPlanGeneralDate,
              acccessSalaryPlanHistoryDetails: user.acccessSalaryPlanHistoryDetails,
              acccessSalaryPlanHistoryID: user.acccessSalaryPlanHistoryID,
              acccessSalaryPlanHistoryHistoryID: user.acccessSalaryPlanHistoryHistoryID,
              acccessSalaryPlanHistoryorderNumber: user.acccessSalaryPlanHistoryorderNumber,
              acccessSalaryPlanHistoryNumbs: user.acccessSalaryPlanHistoryNumbs,
              acccessSalaryPlanHistorySum: user.acccessSalaryPlanHistorySum,
              acccessSalaryPlanHistoryUser: user.acccessSalaryPlanHistoryUser,
              acccessSalaryPlanHistoryDate: user.acccessSalaryPlanHistoryDate,
              acccessSalaryPlanHistoryDetailspositionarticleID: user.acccessSalaryPlanHistoryDetailspositionarticleID,
              acccessSalaryPlanHistoryDetailspositionName: user.acccessSalaryPlanHistoryDetailspositionName,
              acccessSalaryPlanHistoryDetailspositionNum: user.acccessSalaryPlanHistoryDetailspositionNum,
              acccessSalaryPlanHistoryDetailspositionSum: user.acccessSalaryPlanHistoryDetailspositionSum,
              acccessSalaryPlanHistoryDetailspositionPrice: user.acccessSalaryPlanHistoryDetailspositionPrice,
              acccessSalarySettingAdd: user.acccessSalarySettingAdd,
              acccessSalarySettingDel: user.acccessSalarySettingDel,
              acccessSalarySettingarticleID: user.acccessSalarySettingarticleID,
              acccessSalarySettingname: user.acccessSalarySettingname,
              acccessSalarySettingprice: user.acccessSalarySettingprice,
              acccessSalarySet: user.acccessSalarySet
            });
            MaterialService.updateTextInputs()
          }


          this.form.enable()
        },
        error => MaterialService.toast(error.error.message)
      )
  }

  triggerClick() {
    this.inputRef.nativeElement.click()
  }

  deleteCategory() {
    const decision = window.confirm(`Вы уверены, что хотите удалить категорию "${this.user.name}"`)

    if (decision) {
      this.administratorService.delete(this.user._id)
        .subscribe(
          response => MaterialService.toast(response.message),
          error => MaterialService.toast(error.error.message),
          () => this.router.navigate(['/administrator'])
        );
    }
  }

  ngOnDestroy() {
    this.selectTab.destroy()
    if (this.aSub) {
      this.aSub.unsubscribe()
    }
  }

  ngAfterViewInit() {
    this.selectTab = MaterialService.initTabs(this.selectTabRef)
    this.selectSalaryTab = MaterialService.initTabs(this.selectSalaryTabRef)
    this.selectSalaryPlanTab = MaterialService.initTabs(this.selectSalaryPlanTabRef)
  }

  onSubmit() {
    let obs$;
    this.form.disable();

    if (this.isNew === true) {
      obs$ = this.auth.register(this.form.value);
    }
    else {
      obs$ = this.administratorService.update(
        this.user._id,
        this.form.value
    );
    }
    obs$.subscribe(
      user => {
        this.user = user;
        this.form.enable()
        MaterialService.toast('Изменения сохранены.');

      },

      error => {
        this.form.enable();
        MaterialService.toast(error.error.message);

      }
    );
  }

}
